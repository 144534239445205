import React, { useContext, useState } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Typography2 from '@c/Typographies/Typography2';
import { Controller, useForm } from 'react-hook-form';
import { Button, CircularProgress, debounce, TextField } from '@mui/material';
import globalStyles from '../styles.module.scss';
import EmailIcon from '@mui/icons-material/Email';
import KeyIcon from '@mui/icons-material/Key';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import styles from './styles.module.scss';
import PersonIcon from '@mui/icons-material/Person';
import { theme } from 'theme/theme';
import {
  checkEmail,
  checkUsername,
  loginUser,
  registerUser,
} from 'api/user.service';
import { NotificationContext } from '@c/notification/NotificationProvider';
import { NotificationType } from 'shared/interfaces/notification.interface';
import TypeInput from './TypeInput';
import { useDispatch } from 'react-redux';
import { getUserInfo } from 'store/actions/user.actions';
import { useRouter } from 'next/router';
import ReCAPTCHA from 'react-google-recaptcha';

type RegisterStepProps = {
  onRegister: () => void;
  onBack: () => void;
};

const RegisterStep: React.FC<RegisterStepProps> = ({ onRegister, onBack }) => {
  const { handleSubmit, register, formState, watch, control } = useForm();
  const { errors } = formState;
  const [showPass, setShowPass] = useState(false);
  const [loading, setLoading] = useState(false);
  const notify = useContext(NotificationContext);
  const [validationErrors, setValidationErrors] = useState('');
  const dispatch = useDispatch();
  const isGirl = watch('type') === 'girl';
  const handleSubmitRegister = async (e) => {
    setLoading(true);
    try {
      const existsValidationError = await handleManualValidation(
        e.username,
        e.email,
      );
      setValidationErrors(existsValidationError);
      if (existsValidationError) return false;

      await registerUser(e);
      await loginUser(e);
      await dispatch(getUserInfo());
      onRegister();
      // notify(NotificationType.Success, 'Аккаунт успешно создан');
    } catch {
      notify(NotificationType.Error, 'Пожалуйста, попробуйте позже', 'Ошибка');
    } finally {
      setLoading(false);
    }
  };

  const handleManualValidation = async (name: string, email: string) => {
    const usernameExists = await checkUsername(name, email);
    const emailExists = await checkEmail(name, email);
    if (usernameExists) {
      return 'Имя пользователя уже занято';
    }

    if (emailExists) {
      return 'Email уже занят';
    }

    return '';
  };

  return (
    <form
      onSubmit={handleSubmit(handleSubmitRegister)}
      className={globalStyles.form_container}
    >
      <Typography2
        weight="400"
        size="m"
        className={styles.back_row}
        color={theme.palette.primary.main}
        onClick={onBack}
      >
        <ChevronLeftIcon color="primary" />
        Назад
      </Typography2>
      <div className={styles.headingRow}>
        <Typography2 weight="600" size="xl" className={styles.heading}>
          Регистрация
        </Typography2>
        {isGirl && (
          <Typography2
            weight="400"
            size="s"
            className={styles.subheading}
            color={theme.palette.textTransluent.main}
          >
            Единый логин и пароль у сайта и <a href="/app">приложения</a>
          </Typography2>
        )}
      </div>
      <TextField
        {...register('username', {
          required: 'Пожалуйста, заполните имя пользователя',
          maxLength: {
            value: 32,
            message: 'Ваш ник слишком длинный',
          },
        })}
        name="username"
        size="small"
        aria-label="username"
        placeholder="Логин"
        fullWidth
        error={!!errors.username}
        helperText={errors?.username?.message}
        InputProps={{
          startAdornment: (
            <PersonIcon className={styles.icon} color="textTransluent" />
          ),
        }}
      />
      <TextField
        {...register('email', {
          required: 'Пожалуйста, укажите почту',
          pattern: {
            value:
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/i,
            message: 'Введите правильный адрес почты',
          },
          maxLength: {
            value: 64,
            message: 'Ваша почта слишком длинная',
          },
        })}
        size="small"
        aria-label="email"
        placeholder="Email"
        fullWidth
        error={!!errors.email}
        helperText={errors?.email?.message}
        InputProps={{
          startAdornment: (
            <EmailIcon className={styles.icon} color="textTransluent" />
          ),
        }}
      />
      <TextField
        {...register('password', {
          required: 'Пожалуйста, заполните имя пользователя или почту',
          maxLength: {
            value: 32,
            message: 'Ваш пароль слишком длинный',
          },
        })}
        size="small"
        placeholder="Пароль"
        fullWidth
        aria-label="password"
        type={showPass ? 'text' : 'password'}
        error={!!errors.password}
        helperText={errors?.password?.message}
        InputProps={{
          startAdornment: (
            <KeyIcon className={styles.icon} color="textTransluent" />
          ),
          endAdornment: showPass ? (
            <VisibilityIcon
              className={styles.icon}
              color="textTransluent"
              style={{ cursor: 'pointer' }}
              onClick={() => setShowPass(!showPass)}
            />
          ) : (
            <VisibilityOffIcon
              className={styles.icon}
              color="textTransluent"
              style={{ cursor: 'pointer' }}
              onClick={() => setShowPass(!showPass)}
            />
          ),
        }}
      />
      <TextField
        {...register('confirmPassword', {
          required: 'Пожалуйста, заполните имя пользователя или почту',
          validate: (val: string) => {
            if (watch('password') != val) {
              return 'Пароли не совпадают';
            }
          },
        })}
        size="small"
        name="confirmPassword"
        placeholder="Повторите пароль"
        fullWidth
        aria-label="confirmPassword"
        error={!!errors.confirmPassword}
        helperText={errors?.confirmPassword?.message}
        type={showPass ? 'text' : 'password'}
        InputProps={{
          startAdornment: (
            <KeyIcon className={styles.icon} color="textTransluent" />
          ),
        }}
      />
      {validationErrors && (
        <Typography2 weight="400" size="s" color={theme.palette.error.main}>
          Аккаунт или имя пользователя уже используется
        </Typography2>
      )}
      {errors?.type?.message && (
        <Typography2 weight="400" size="s" color={theme.palette.error.main}>
          {errors?.type?.message}
        </Typography2>
      )}
      <Controller
        control={control}
        name="type"
        rules={{ required: 'Пожалуйста, укажите тип аккаунта' }}
        render={(props) => (
          <TypeInput
            value={props.field.value}
            onChange={props.field.onChange}
            options={['client', 'girl']}
          />
        )}
      />
      <div>
        <Controller
          name="captcha"
          control={control}
          rules={{
            required: 'Пройдите каптчу',
          }}
          render={(props) => (
            <ReCAPTCHA
              sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITEKEY}
              onChange={() => props.field.onChange(true)}
            />
          )}
        />
        {!!errors.captcha && (
          <Typography2
            weight="400"
            size="m"
            color={theme.palette.primary.main}
            className={styles.center}
          >
            {errors?.captcha?.message}
          </Typography2>
        )}
      </div>
      <Button variant="contained" type="submit" fullWidth size="large">
        {loading ? (
          <CircularProgress size={26} color="secondary" />
        ) : (
          'Зарегистрироваться'
        )}
      </Button>
      <div className={styles.bottom}>
        <Typography2
          weight="400"
          size="m"
          color={theme.palette.textBold.main}
          onClick={onBack}
        >
          Уже есть аккаунт?{' '}
        </Typography2>
        <Typography2
          weight="400"
          size="m"
          onClick={onBack}
          color={theme.palette.primary.main}
          className={styles.link}
        >
          Войти
        </Typography2>
      </div>
    </form>
  );
};

export default RegisterStep;
